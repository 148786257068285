$yiq-contrasted-threshold: 175 !default;

$primary: $blue !default;
$secondary: $gray-700 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-200 !default;
$dark: $gray-800 !default;

$scroll-track: $gray-700 !default;
$scroll-thumb: $gray-600 !default;
$badge-bg: $gray-700 !default;

// Body

$body-bg: $dark !default;
$body-color: $light !default;

// Links

$link-color: $info !default;

// Lists

$list-group-action-color: $gray-100 !default;

// Tables

$table-row-odd: lighten($gray-800, 3) !default;
$table-row-even: $gray-800 !default;
$table-border-color: $gray-700 !default;
$table-bg: lighten($gray-800, 3) !default;
$table-border-top: #2f2f2f;

// Forms

$input-border-color: $gray-800 !default;
$input-border-color-hover: $gray-600 !default;
$input-group-addon-color: $gray-500 !default;
$input-group-addon-bg: $gray-700 !default;
$custom-file-color: $gray-500 !default;
$custom-file-border-color: $body-bg !default;

// Dropdowns

$dropdown-bg: $gray-700 !default;
$dropdown-border-color: $gray-900 !default;
$dropdown-divider-bg: $gray-900 !default;
$dropdown-link-color: $white !default;
$dropdown-link-hover-color: $white !default;
$dropdown-link-hover-bg: $primary !default;

// Navs

$nav-link-padding-x: 2rem !default;
$nav-link-disabled-color: $gray-500 !default;
$nav-tabs-border-color: $gray-700 !default;
$nav-tabs-link-hover-border-color: $nav-tabs-border-color $nav-tabs-border-color transparent !default;
$nav-tabs-link-active-color: $white !default;
$nav-tabs-link-active-border-color: $nav-tabs-border-color $nav-tabs-border-color transparent !default;

// Navbar

$navbar-padding-y: 1rem !default;
$navbar-dark-color: rgba($white, .6) !default;
$navbar-dark-hover-color: $white !default;
$navbar-light-color: rgba($gray-900, .7) !default;
$navbar-light-hover-color: $gray-900 !default;
$navbar-light-active-color: $gray-900 !default;
$navbar-light-toggler-border-color: rgba($gray-900, .1) !default;

// Common navigation button
$common-nav-btn-active-bg: $arapawa;

// Pagination

$pagination-color: $white !default;
$pagination-bg: $success !default;
$pagination-border-width: 0 !default;
$pagination-border-color: transparent !default;
$pagination-hover-color: $white !default;
$pagination-hover-bg: lighten($success, 10%) !default;
$pagination-hover-border-color: transparent !default;
$pagination-active-bg: $pagination-hover-bg !default;
$pagination-active-border-color: transparent !default;
$pagination-disabled-color: $white !default;
$pagination-disabled-bg: darken($success, 15%) !default;
$pagination-disabled-border-color: transparent !default;

// Jumbotron

$jumbotron-bg: $gray-800 !default;

// Cards

$card-cap-bg: $gray-700 !default;
$card-bg: $gray-800 !default;

// Popovers

$popover-bg: $gray-700 !default;
$popover-header-bg: $gray-600 !default;

// Toasts

$toast-background-color: $gray-700 !default;
$toast-header-background-color: $gray-800 !default;

// Modals

$modal-content-bg: $gray-800 !default;
$modal-content-border-color: $gray-700 !default;
$modal-header-border-color: $gray-700 !default;

// Progress bars

$progress-bg: $gray-600 !default;

// List group

$list-group-bg: $gray-700 !default;
$list-group-border-color: $gray-700 !default;
$list-group-hover-bg: lighten($gray-700, 5%) !default;
$list-group-color: $gray-200 !default;

// Breadcrumbs

$breadcrumb-bg: $gray-700 !default;

// Inputs

$input-bg: $gray-700 !default;
$input-disabled-bg: $gray-800 !default;
$input-color: $gray-100 !default;
$text-muted: $gray-500 !default;
$form-select-indicator-color: $light !default;

// Accordion

$accordion-button-active-color: $light !default;
$accordion-button-active-bg: $blue !default;
$accordion-border-color: $gray-700 !default;

// App bar
$app-bar-blue: $primary !default;
$app-bar-green: $green !default;
$app-bar-purple: $purple !default;

// Search results author
$results-author-color: $gray-400;

// Labels

$label-color: #9b9b9b;

// Buttons

$btn-close-color:  $gray-100;
$button-hover-color: rgba(75, 125, 150, .1);

// Text
$text-color-secondary: #9b9b9b;